import logo from './logo.svg';
import './App.css';
import background_img from './bg_img.jpg';
import img_1 from './1.jpg'
import img_2 from './2.jpg'
import img_3 from './3.jpg'
import img_4 from './4.jpg'
import img_5 from './5.jpg'
import img_6 from './6.jpg'
import iimg_1 from './img_1.jpeg'
import iimg_2 from './img_2.jpeg'
import iimg_3 from './img_3.jpeg'
import iimg_4 from './img_4.jpeg'
import iimg_6 from './img_6.jpeg'
import iimg_7 from './img_7.jpeg'
import iiimg_1 from './iiimg_1.jpeg'
import iiimg_2 from './iiimg_2.jpeg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faYoutube,
  faFacebook,
  faTwitter,
  faInstagram,
  faTiktok
} from "@fortawesome/free-brands-svg-icons";


function App() {
  return (
    
    <div className="App">
      <div style={{ backgroundImage:`url(${background_img})`
    }} className="Background">
      <div className='blur'></div>
      </div>
      <header className="App-header">
        <h1>
      CELLO SHISHA LOUNGE
      </h1>
      </header>
      
      
      
      <div className='txt-carousel'>
        <div className='txt-c-element'>W</div>
        <div className='txt-c-element'>I</div>
        <div className='txt-c-element'>L</div>
        <div className='txt-c-element'>L</div>
        <div className='txt-c-element'>K</div>
        <div className='txt-c-element'>O</div>
        <div className='txt-c-element'>M</div>
        <div className='txt-c-element'>M</div>
        <div className='txt-c-element'>E</div>
        <div className='txt-c-element'>N</div>
      </div>
      <p>
        Willkommen in unserer gemütlichen Shisha-Bar, wo Genuss und Entspannung im Mittelpunkt stehen. Unser vielfältiges Sortiment an Shisha-Geschmacksrichtungen lässt keine Wünsche offen. Genießen Sie exotische Aromen und verbringen Sie unvergessliche Stunden in guter Gesellschaft.
        </p>
      <div className='img-carousel'>
        <img className='img-c-element' src={img_1} alt='img'/>
        <img className='img-c-element' src={img_4} alt='img'/>
        <img className='img-c-element' src={img_5} alt='img'/>
        <img className='img-c-element' src={img_3} alt='img'/>
        <img className='img-c-element' src={img_2} alt='img'/>
        <img className='img-c-element' src={iimg_1} alt='img'/>
       


</div>
        
        <p>
        Unsere Shisha-Bar ist ein Ort der Ruhe und Entspannung, perfekt für Treffen mit Freunden oder für Momente der eigenen Erholung. Lassen Sie sich von unseren Shisha-Meistern verwöhnen, während Sie sich von der hektischen Welt draußen erholen. Bei uns können Sie sich zurücklehnen, lachen und den Alltagsstress vergessen.
        </p>
        <div class="row">
  <div className="column-2">
  <img className='img-el' src={iimg_6} alt='img'/>  </div>
  <div className="column-2">
  <img  className='img-el' src={iimg_4} alt='img'/>
  </div>
  
</div>
        <div >
        
        <img className='img-el-2' src={iimg_2} alt='img'/>
        <img className='img-el-2' src={iimg_3} alt='img'/>
        <img className='img-el-2' src={img_6} alt='img'/>
        <img className='img-el' src={iimg_7} alt='img'/>

        </div>


        <div class="row">
  <div className="column">
    <img src={iiimg_2} alt="img"/>
  </div>
  <div className="column">
    <img src={iiimg_1} alt="img" />
  </div>
  
</div>


        <h2>Telefon &amp; WhatsApp
        </h2>
        <h2>+49 177 330 153 6</h2>
        <p className="social-container">
        
        <a
          href="https://www.tiktok.com/@celloshisha1"
          className="tiktok social"
        >
          <FontAwesomeIcon icon={faTiktok} size="2x" />
        </a>
        <a
          href="http://www.instagram.com/celloshisha/"
          className="instagram social"
        >
          <FontAwesomeIcon icon={faInstagram} size="2x" />
        </a>
        
      </p>
        <a style={{fontWeight:"bold", fontSize:"28px"}} href='https://www.cello-bar.de'>MENÜ...</a>
        <h2>&Ouml;ffnungszeiten:</h2>
        <table>
 
  
  <tr>
    <th scope="row">Montags:</th>
    <td>10:00 - 02:00 Uhr
        </td>
  </tr><tr>
    <th scope="row">Dienstags:</th>
    <td>10:00 - 02:00 Uhr
        </td>
  </tr><tr>
    <th scope="row">Mittwochs:</th>
    <td>10:00 - 02:00 Uhr
        </td>
  </tr><tr>
    <th scope="row">Donnerstags:</th>
    <td>10:00 - 02:00 Uhr
        </td>
  </tr><tr>
    <th scope="row">Freitags:</th>
    <td>10:00 - 04:00 Uhr
        </td>
  </tr><tr>
    <th scope="row">Samstags:</th>
    <td>10:00 - 04:00 Uhr
        </td>
  </tr>
  <tr>
    <th scope="row">Sonntags:</th>
    <td>10:00 - 02:00 Uhr
        </td>
  </tr>
  
</table>

        <h2>Adresse</h2>
        <h4>Triererstra&szlig;e 39<br/>66663 Merzig<br/>Deutschland</h4>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2594.1255591475256!2d6.6335241769313695!3d49.444343859292474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47950be510235991%3A0x7b8e0afe6d36f880!2sCello%20Shisha%20Lounge!5e0!3m2!1sde!2sde!4v1698653428400!5m2!1sde!2sde" width="300" height="230" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

        <a href="tel:0049 177 330 153 6">
      <button className="default-btn default-btn-mobile color-green">Direkt anrufen</button></a>
        
    </div>
  );
}

export default App;
